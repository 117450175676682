import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import './index.scss';
import App from './App';
import Cookies from 'js-cookie';

// Function to apply the theme based on the input
const applyTheme = (theme) => {
  document.body.className = theme === 'dark' ? 'dark-theme' : 'light-theme';
  Cookies.set('theme', theme);
};

// Function to detect and apply the initial theme
const detectAndApplyTheme = () => {
  const savedTheme = Cookies.get('theme');
  if (savedTheme) {
    applyTheme(savedTheme);
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    applyTheme('dark');
  } else {
    applyTheme('light');
  }
};

// Function to listen for theme changes
const setupThemeListener = () => {
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  darkModeMediaQuery.addEventListener('change', (e) => {
    const newTheme = e.matches ? 'dark' : 'light';
    applyTheme(newTheme);
  });
};

// Run the initial detection and apply the theme
detectAndApplyTheme();

// Set up the listener for theme changes
setupThemeListener();

// Create the root and render the app
const container = document.getElementById('root');
const root = createRoot(container); // Create root using createRoot
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
