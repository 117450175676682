import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie'; // Keep the import now that we are using Cookies
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './i18n/en.json';
import ru from './i18n/ru.json';
import lv from './i18n/lv.json';
import de from './i18n/de.json';
import fr from './i18n/fr.json';
import es from './i18n/es.json';
import zh from './i18n/zh.json';

const resources = {
  en: { translation: en },
  ru: { translation: ru },
  lv: { translation: lv },
  de: { translation: de },
  fr: { translation: fr },
  es: { translation: es },
  zh: { translation: zh },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

// Store the user's language preference in a cookie for 2 years
i18n.on('languageChanged', (lng) => {
  Cookies.set('i18next', lng, { expires: 730 }); // 730 days = 2 years
});

export default i18n;
