import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Header.scss';
import logoSmall from '../../assets/images/logo_small.svg';
import enFlag from '../../assets/images/flags/en.png';
import ruFlag from '../../assets/images/flags/ru.png';
import lvFlag from '../../assets/images/flags/lv.png';
import deFlag from '../../assets/images/flags/de.png';
import frFlag from '../../assets/images/flags/fr.png';
import esFlag from '../../assets/images/flags/es.png';
import zhFlag from '../../assets/images/flags/zh.png';

import instagramLightIcon from '../../assets/icons/instagram_dark.svg';
import instagramDarkIcon from '../../assets/icons/instagram_light.svg';
import xLightIcon from '../../assets/icons/x_dark.svg';
import xDarkIcon from '../../assets/icons/x_light.svg';
import emailLightIcon from '../../assets/icons/email_dark.svg';
import emailDarkIcon from '../../assets/icons/email_light.svg';
import whatsappLightIcon from '../../assets/icons/whatsapp_dark.svg';
import whatsappDarkIcon from '../../assets/icons/whatsapp_light.svg';
import telegramLightIcon from '../../assets/icons/telegram_dark.svg';
import telegramDarkIcon from '../../assets/icons/telegram_light.svg';

const Header = ({ theme, toggleTheme }) => {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollingDown, setScrollingDown] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const instagramIcon = theme === 'dark' ? instagramLightIcon : instagramDarkIcon;
  const emailIcon = theme === 'dark' ? emailLightIcon : emailDarkIcon;
  const whatsappIcon = theme === 'dark' ? whatsappLightIcon : whatsappDarkIcon;
  const telegramIcon = theme === 'dark' ? telegramLightIcon : telegramDarkIcon;
  const xIcon = theme === 'dark' ? xLightIcon : xDarkIcon;

  useEffect(() => {
    // Disable scroll when the menu is expanded
    document.body.style.overflow = isExpanded ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto'; // Clean up on component unmount
    };
  }, [isExpanded]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrollingDown(currentScrollTop > lastScrollTop);
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const toggleMenu = () => setIsExpanded(prev => !prev);

  const languages = [
    { code: 'en', name: 'EN', flag: enFlag },
    // { code: 'ru', name: 'RU', flag: ruFlag },
    // { code: 'lv', name: 'LV', flag: lvFlag },
    // { code: 'de', name: 'DE', flag: deFlag },
    // { code: 'fr', name: 'FR', flag: frFlag },
    { code: 'es', name: 'ES', flag: esFlag },
    // { code: 'zh', name: 'ZH', flag: zhFlag },
  ];

  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0]; // Fallback to 'en' if not found

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setMenuOpen(false); // Close the menu after selecting a language
  };

  return (
    <header id="home" className={`header ${scrollingDown ? 'header-hidden' : 'header-visible'}`}>
      <div className="headerMini">
        <div
          className={`${isExpanded ? 'menu-expanded' : 'menu-collapsed'}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <nav>
            <ul>
              <li><a href="#services">{t('header.services')}</a></li>
              <li><a href="#work">{t('header.work')}</a></li>
              {/* <li><a href="#contact">{t('header.contact')}</a></li>
              <li><a href="#about">{t('header.about')}</a></li> */}
            </ul>
          </nav>
        </div>
        <div className="header-logo">
          <a href="#home">
            <img src={logoSmall} alt="Logo" />
          </a>
        </div>
        <div className="header-actions">
          <button className="theme-button-medium" onClick={toggleTheme}>
            {theme === 'light' ? t('header.darkMode-medium') : t('header.lightMode-medium')}
          </button>
          <div
            className="language-dropdown"
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}
          >
            <div className="language-select">
              <img src={currentLanguage.flag} alt={currentLanguage.name} />
              <span>{currentLanguage.name}</span>
            </div>
            {menuOpen && (
              <ul className="language-menu">
                {languages
                  .filter(lang => lang.code !== i18n.language)
                  .map(lang => (
                    <li key={lang.code} onClick={() => changeLanguage(lang.code)}>
                      <img src={lang.flag} alt={lang.name} /> {lang.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="headerMaxi">
        <div className="header-logo">
          <a href="#home">
            <img src={logoSmall} alt="Logo" />
          </a>
        </div>
        <nav className="header-nav">
          <a href="#services">{t('header.services')}</a>
          <a href="#work">{t('header.work')}</a>
          {/* <a href="#contact">{t('header.contact')}</a> */}
          {/* <a href="#about">{t('header.about')}</a> */}
        </nav>
        <div className="header-actions">
          <button className="theme-button-large" onClick={toggleTheme}>
            {theme === 'light' ? t('header.darkMode-large') : t('header.lightMode-large')}
          </button>
          <button className="theme-button-medium" onClick={toggleTheme}>
            {theme === 'light' ? t('header.darkMode-medium') : t('header.lightMode-medium')}
          </button>
          <div
            className="language-dropdown"
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}
          >
            <div className="language-select">
              <img src={currentLanguage.flag} alt={currentLanguage.name} />
              <span>{currentLanguage.name}</span>
            </div>
            {menuOpen && (
              <ul className="language-menu">
                {languages
                  .filter(lang => lang.code !== i18n.language)
                  .map(lang => (
                    <li key={lang.code} onClick={() => changeLanguage(lang.code)}>
                      <img src={lang.flag} alt={lang.name} /> {lang.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
        <div className="header-contact">
          <a href="https://ig.me/m/amdigitalstudio_" aria-label="Instagram" className="contact-icon">
            <img src={instagramIcon} alt="Instagram" className="instagram" />
          </a>
          <a href="https://twitter.com" aria-label="Twitter" className="contact-icon">
            <img src={xIcon} alt="X ex-Twitter" className="twitter" />
          </a>
          <a href="mailto:andreymanuilovweb@gmail.com" aria-label="Email" className="contact-icon">
            <img src={emailIcon} alt="Email" className="email" />
          </a>
          <a href="https://wa.me/37123204492" aria-label="WhatsApp" className="contact-icon">
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp" />
          </a>
          <a href="https://telegram.me/@am_digital_studio" aria-label="Telegram" className="contact-icon">
            <img src={telegramIcon} alt="Telegram" className="telegram" />
          </a>
        </div>
    </header>
  );
};

export default Header;
