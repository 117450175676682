import React, { useState, useEffect } from 'react';
import './App.scss';
import Header from './components/Header';
import Hero from './components/Hero';
import CookieNotification from './components/CookieNotification'; // Import the cookie notification
import './i18n';
import Cookies from 'js-cookie';

// Lazy load the non-visible components
// const AboutUs = React.lazy(() => import('./components/AboutUs'));
const Services = React.lazy(() => import('./components/Services'));
const Work = React.lazy(() => import('./components/Work'));
const LilIntro = React.lazy(() => import('./components/LilIntro'));
// const Connect = React.lazy(() => import('./components/Connect'));
const Footer = React.lazy(() => import('./components/Footer'));

const App = () => {
  const [theme, setTheme] = useState(Cookies.get('theme') || (document.body.classList.contains('dark-theme') ? 'dark' : 'light'));
  const [showLandscapeWarning, setShowLandscapeWarning] = useState(false);
  const [showHero, setShowHero] = useState(true);

  useEffect(() => {
    document.body.className = theme === 'dark' ? 'dark-theme' : 'light-theme';
    Cookies.set('theme', theme);
  }, [theme]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      
      // Check for landscape warning
      if (height <= 768 && height < width && width <= 1024) {
        setShowLandscapeWarning(true);
      } else {
        setShowLandscapeWarning(false);
      }
      
      // Hide Hero on mobile devices
      if (width <= 768) { // Adjust the threshold as needed
        setShowHero(false);
      } else {
        setShowHero(true);
      }
    };

    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <div className="App">
      {showLandscapeWarning && (
        <div className="landscape-warning">
          Please rotate your device to portrait mode
        </div>
      )}
      <Header theme={theme} toggleTheme={toggleTheme} />
      {showHero && <Hero theme={theme} />}

      {/* Use Suspense for lazy-loaded components with a fallback */}
      <React.Suspense fallback={<div>Loading section...</div>}>
        <LilIntro theme={theme} />
      </React.Suspense>
      <React.Suspense fallback={<div>Loading section...</div>}>
        <Services theme={theme} />
      </React.Suspense>
      <React.Suspense fallback={<div>Loading section...</div>}>
        <Work theme={theme} />
      </React.Suspense>
      {/* <React.Suspense fallback={<div>Loading section...</div>}>
        <Connect theme={theme} />
      </React.Suspense> */}
      {/* <React.Suspense fallback={<div>Loading section...</div>}>
        <AboutUs theme={theme} />
      </React.Suspense> */}
      <React.Suspense fallback={<div>Loading footer...</div>}>
        <Footer theme={theme} />
      </React.Suspense>

      <CookieNotification />
    </div>
  );
};

export default App;
