import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import './CookieNotification.scss';
import { ReactComponent as CookieIcon } from '../../assets/icons/cookie.svg';

const CookieNotification = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 730 }); // Accept for 2 years
    setVisible(false);
  };

  const declineCookies = () => {
    Cookies.set('cookieConsent', 'declined', { expires: 730 }); // Decline for 2 years
    Cookies.remove('i18next'); // Remove language cookie
    Cookies.remove('theme'); // Remove theme cookie
    setVisible(false);
  };

  const handleMouseEnter = () => {
    setPopupVisible(true);
  };

  const handleMouseLeave = () => {
    // Keep the popup visible if the mouse is over the popup itself
    setTimeout(() => {
      if (!document.querySelector('.cookie-notification:hover') && !document.querySelector('.cookie-popup:hover')) {
        setPopupVisible(false);
      }
    }, 200);
  };

  return (
    visible && (
      <div
        className="cookie-notification"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CookieIcon className="cookie-icon" />
        {popupVisible && (
          <div
            className="cookie-popup"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <p>{t('cookie.message')}</p>
            <div className="cookie-actions">
              <button onClick={acceptCookies}>{t('cookie.accept')}</button>
              <button onClick={declineCookies}>{t('cookie.decline')}</button>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default CookieNotification;
