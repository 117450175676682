import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Hero.scss';
import ErrorBoundary from './ErrorBoundary';

import instagramLightIcon from '../../assets/icons/instagram_dark.svg';
import instagramDarkIcon from '../../assets/icons/instagram_light.svg';
import xLightIcon from '../../assets/icons/x_dark.svg';
import xDarkIcon from '../../assets/icons/x_light.svg';
import emailLightIcon from '../../assets/icons/email_dark.svg';
import emailDarkIcon from '../../assets/icons/email_light.svg';
import whatsappLightIcon from '../../assets/icons/whatsapp_dark.svg';
import whatsappDarkIcon from '../../assets/icons/whatsapp_light.svg';
import telegramLightIcon from '../../assets/icons/telegram_dark.svg';
import telegramDarkIcon from '../../assets/icons/telegram_light.svg';
import particlesPlaceholderDark from '../../assets/images/particles_dark.jpg';
import particlesPlaceholderLight from '../../assets/images/particles_light.jpg';

const gifUrls = [
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZ3hqamcycjVjZXFzaHp3b3VvdnU0MHdkZm9wNjJ3bjQwYnVhdzIweCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/cE02lboc8JPO/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMDNqcXk0NTFia2ZoaWtxZmp5aGdndm9hM3QzZXlwZGFrcHdsZ3ZrdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/S2IfEQqgWc0AH4r6Al/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMTkwdGE4bW8yZnV4NnVwOTV1MzF6MWNuNDYyejBwN3lsdHJhYjdzdCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/VdAvVcQLJDwKKDUDJR/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExNXRxaW4xcDlvY2ZvM2JqdmExZmtnYzB3N25vbm41OXV1eWpzMDgxZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/fTI9mBoWLef8k/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMHE5aWc3aWhvNXBoeTBjZGVybTV1dDUzdDl3aGV0cG94aThybXc4OSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/L2eThScfeqittTbpWI/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZGVzaDhyOTJyN2RqaXVnY3ZmeWdseXlsemY0bHVpODhqbXQxeWl3MSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/BElb9DVpHezcZufOhl/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcTI1cmlxZ2Zmdm1nYjhwbG90MzZ6MTliOGF0bXBwcmo3djhybDVmdCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/cnE8RvRxoAVXgCIxYV/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZ2F2N3lsdmgzazVxYzVzMXNwbWpjZWJuYmJ6OTJoMmpnMjk4YWY2bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/6XeQfKBcO3K6c/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExbjZnNzNuNW1vc3p4cHJrMDg5cmMzcGJ2dHBlczltNmZ4NzhsOGV4bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/xTiIzJSKB4l7xTouE8/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcHBqY2MxOWxteXV2Zm9hNHNkdGk2a2JtdTk0c3hiNGQ3YWh0OTk0cSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/djRJNZqj508sE/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeWo0MHozYmZ4a3loejFmcXp1ZDh3OHltamU3bmVpYjVpcHVrcGZhayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/PnUatAYWMEMvmiwsyx/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExYnY2ZjJwMW96N2NhbTF4bmNkcjJvaGxvYWJsanc5ZzZydGE1ODZjMSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/6Vqi4rVX8yJ1b8Wrmk/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExam54YjNqb3g0c3V2Nm9uZnZiOGJkdGIxNzUybW5rdnN6dzk5NXY4biZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Cmr1OMJ2FN0B2/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExY3N5N3o0OXdwNWNzemFwMmtkYmc4eXNnemJrNHFjZmxmNzl1NnppeiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/OnnUZxcHsbBN6/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExOWZ4MWVycnY1anQ0cHk3aWx0eXFiaDZxamNvbmZ4aW13dHVnbXhlcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/GqtNlBsWoEXDy/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMnNibDZkYWs1bnR4eWI2NXp1OXN3bndua21hbmxiMm9nbGFzZml6cyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/28GHfhGFWpFgsQB4wR/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExd2h2Z2pkZmRjMjFscjVtczA1dzJkZXB2NzZzYnh1Y3A5ZG03NjFsciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/27c7Jo2GU5tpCEQT0y/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMHlsdzBkMHd2ZHh2ZW56OGZldmtiMDF1eGd2dWZzY3FmZXg4cGJmdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6ZtpxSZbQRRnwCKQ/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeWpnZHkybGZjczVkbW5rcmV6YzZvZzdoYmx3c3U0NjNvM3Zpcm5ibyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/dzaUX7CAG0Ihi/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExdzZrbzFzcnVyNGF6YjN1OTRmejE1dGQ1eXhrNGY0Njk2dzYyNjR3cCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l0HlHF8PFihpCh1RK/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExNzY3MWFrNXQ5enhtendoM3c3aWI4OXc1dnNieHJpcmJhOXRwbGxldyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/xT9IgG50Fb7Mi0prBC/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZXN5ZjdmYWk1cmhnNHNtODUxOTlzdGczenkyYXJheGp4YTExa2Y3dyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o85xwGZR5UtB6SiL6/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExejJxOTh6OGkxMjJwN3g4NmMzMjR4ejJqdGxuZDBzMDhrMnI3MnhidCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/PK1YQhAoBOpP2/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcmtpZGM1bDRybzBmZTZidmhjYWxlMXltcm96dHg5NHVldnU4OXZ5YSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/84EyU2Adem39S/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExamlxaW90eTZvMWQ3Mm40c3pzcjNmZ2ViczFpdmJ2N2s2ZnIzdGs4cSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/JGKwzgpKXVYaY/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExY2cweWFteTk2cmNqODJpNXF3OHhjeWdhZGhranRlcGVvajBhODFmeSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3ornk57KwDXf81rjWM/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExd25peG9wdDZybXpjcGNoOWZ0OTA1c3lxeDRyeW9ncGF0NHY5dWl3NCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3zjQXYf55Ih31V2J5k/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMzM4bmNkbTRnaGlydW0zZm13ZTY3Yzc2angwMWwwNTc1N3Y1bzdtZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/4WBOhLOfnxmpPl2e6P/giphy.gif',
  'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExaHVsOXhkMzVkOTJ1a29ibzdpejZ2cmpxenF2Yjg0OXZjeTE2MDVqcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/BI3bNv1NJMC7YzatXd/giphy.gif'
];

// Add a utility function to determine which Spline scene to use based on screen width
const getSplineSceneUrl = (theme, width) => {
  if (width >= 2300 && width <= 2560) {
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdark19201080copy-c1ad571975c0a07ed6db06f5790e44ff/'
      : 'https://my.spline.design/amdigitalstudioparticleslight19201080copy-fc3f0f4da3d714074d4e85375e4f9b5b/';
  } else if (width >= 2000 && width < 2300) {
    // Step 2: For screen widths between 2000px and 2300px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdark19201080copycopy-6d4cee2fa81dab6c686661e45806db94/'
      : 'https://my.spline.design/amdigitalstudioparticleslight19201080copycopycopy-ad0414d1d6c98f896db15b4f66089cc8/';
  } else if (width >= 1760 && width < 2000) {
    // Step 3: For screen widths between 1760px and 2000px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdark19201080copycopycopy-ce969bbd7b38ca2a009e17b539a687e7/'
      : 'https://my.spline.design/amdigitalstudioparticleslight19201080copycopycopy-4ae509d935f467fcfa0d87a6bd8bb30f/';
  } else if (width >= 1599 && width < 1760) {
    // Step 4: For screen widths between 1599px and 1760px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdark19201080copycopycopy-ce969bbd7b38ca2a009e17b539a687e7/'
      : 'https://my.spline.design/amdigitalstudioparticleslight19201080copycopycopy-4ae509d935f467fcfa0d87a6bd8bb30f/';
  } else if (width >= 1440 && width < 1599) {
    // Step 5: For screen widths between 1440px and 1599px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdark19201080copycopycopy-3f3536d59c1fe06ccd6fda19cfd66d76/'
      : 'https://my.spline.design/amdigitalstudioparticleslight19201080copycopycopy-dd9956ac86dab8556fc3d215977ba7b7/';
  } else if (width >= 1200 && width < 1440) {
    // Step 6: For screen widths between 1200px and 1439px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdark19201080copycopy-12c5fe632aeeef9663e0bf88c3d9b697/'
      : 'https://my.spline.design/amdigitalstudioparticleslight19201080copycopy-e73846974e861cc749a514c620e53d33/';
  } else if (width >= 992 && width < 1200) {
    // Step 7: For screen widths between 992px and 1199px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdark19201080copycopycopy-9644ddd3b42085c0e6fb14b479293887/'
      : 'https://my.spline.design/amdigitalstudioparticleslight19201080copycopycopy-9a795d6a74f08c01aaa89f097966cb12/';
  } else if (width >= 769 && width < 992) {
    // Step 7: For screen widths between 992px and 1199px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdarkmobile576pxwide-f5b0d59576d814460cb111be263074c4/'
      : 'https://my.spline.design/amdigitalstudioparticleslightmobile576pxwidecopy-48aae50e8d8335b18bfd8a2911d7d048/';
  } else if (width >= 577 && width < 769) {
    // Step 7: For screen widths between 992px and 1199px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdarkmobile576pxwidecopy-c4ded02ac3604b4fd916342590b630d3/'
      : 'https://my.spline.design/amdigitalstudioparticleslightmobile576pxwidecopycopy-0f759e74f93867b15e5ab5370b96dd87/';
  } else if (width >= 481 && width < 577) {
    // Step 8: For screen widths between 481px and 576px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdarkmobile576pxwidecopy-a26ed8d0ffeda603830cf555f019fe85/'
      : 'https://my.spline.design/amdigitalstudioparticleslightmobile576pxwidecopycopy-175626fee63045e1907009dce82cb3fa/';
  } else if (width >= 430 && width < 481) {
    // Step 9: For screen widths between 430px and 480px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdarkmobile576pxwidecopycopy-e7a64c0eaa08106a01737769f74535ab/'
      : 'https://my.spline.design/amdigitalstudioparticleslightmobile576pxwidecopycopycopy-e96c0b58c32b2e90e48e44397446f0be/';
  } else if (width >= 390 && width < 430) {
    // Step 10: For screen widths between 390px and 429px
    return theme === 'dark'
      ? 'https://my.spline.design/amdigitalstudioparticlesdarkmobile576pxwidecopycopycopy-13e4ba3da57edbc07a19ad4083d4325f/'
      : 'https://my.spline.design/amdigitalstudioparticleslightmobile576pxwidecopycopycopycopy-a456ccaf4f2c1400fb5cd5feaf7a4b87/';
  }
  // Default scenes for other screen sizes
  return theme === 'dark'
    ? 'https://my.spline.design/amdigitalstudioparticles-70aac80bc5788178692a8ed6cb94260a/'
    : 'https://my.spline.design/amdigitalstudioparticleslight-a6273aab68de74c4ccc2dbb6b2f8e88a/';
};

const Hero = ({ theme }) => {
  const { t } = useTranslation();
  const [hoveredGif, setHoveredGif] = useState(null);
  const [lastGif, setLastGif] = useState(null);
  const [isSplineLoaded, setIsSplineLoaded] = useState(false); // State for Spline loading
  const [isSplineVisible, setIsSplineVisible] = useState(false); // State for Spline visibility
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true); // State for image visibility
  const [hasInitialLoadCompleted, setHasInitialLoadCompleted] = useState(false); // State to differentiate initial load

  const [splineUrl, setSplineUrl] = useState(getSplineSceneUrl(theme, window.innerWidth)); // State to manage Spline URL
  const splineRef = useRef(null); // Ref to the Spline iframe

  const instagramIcon = theme === 'dark' ? instagramLightIcon : instagramDarkIcon;
  const emailIcon = theme === 'dark' ? emailLightIcon : emailDarkIcon;
  const whatsappIcon = theme === 'dark' ? whatsappLightIcon : whatsappDarkIcon;
  const telegramIcon = theme === 'dark' ? telegramLightIcon : telegramDarkIcon;
  const xIcon = theme === 'dark' ? xLightIcon : xDarkIcon;

  let touchTimeoutRef = useRef(null); // Ref to store timeout ID

  const handleHover = () => {
    let randomGif;
    do {
      randomGif = gifUrls[Math.floor(Math.random() * gifUrls.length)];
    } while (randomGif === lastGif);

    setHoveredGif(randomGif);
    setLastGif(randomGif);
  };

  const handleTouchStart = () => {
    handleHover(); // Show GIF when touched
    if (touchTimeoutRef.current) {
      clearTimeout(touchTimeoutRef.current); // Clear any existing timeout to prevent multiple triggers
    }
    touchTimeoutRef.current = setTimeout(() => {
      setHoveredGif(null); // Hide GIF after 5 seconds
    }, 5000); // 5 seconds
  };

  useEffect(() => {
    return () => {
      // Clear timeout when the component is unmounted
      if (touchTimeoutRef.current) {
        clearTimeout(touchTimeoutRef.current);
      }
    };
  }, []);

  const handleSplineLoad = () => {
    setIsSplineLoaded(true);
    setTimeout(() => {
      setIsPlaceholderVisible(false); // Hide the placeholder after Spline is loaded
      setIsSplineVisible(true); // Ensure Spline is visible once loaded
      setHasInitialLoadCompleted(true); // Mark initial load as completed
    }, 2000); // Add 2-second delay for initial load
  };

  // Intersection Observer to detect when Spline is in or near the viewport
  useEffect(() => {
    const handleResize = () => {
      // Update the Spline URL based on the new window width
      setSplineUrl(getSplineSceneUrl(theme, window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [theme]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (isSplineLoaded) {
            if (entry.isIntersecting) {
              if (!isSplineVisible && hasInitialLoadCompleted) {
                setIsPlaceholderVisible(false);
                setTimeout(() => {
                  setIsSplineVisible(true);
                }, 200);
              }
            } else {
              if (isSplineVisible) {
                setIsSplineVisible(false);
                setTimeout(() => {
                  setIsPlaceholderVisible(true);
                }, 200);
              }
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (splineRef.current) {
      observer.observe(splineRef.current);
    }

    return () => {
      if (splineRef.current) {
        observer.unobserve(splineRef.current);
      }
    };
  }, [isSplineLoaded, isSplineVisible, hasInitialLoadCompleted]);

  return (
    <section className={`hero ${isSplineVisible ? 'spline-visible' : ''}`}>
      <div className="hero-content">
        <div className="hero-spline">
          <div className="hero-easter-egg" onMouseEnter={handleHover} onMouseLeave={() => setHoveredGif(null)} onTouchStart={handleTouchStart}>
            <div className="square"></div>
            {hoveredGif && <img src={hoveredGif} alt="Hi" className="gif" />}
          </div>
          <h2 className="hero-greeting">{t('hero.greeting')}</h2>

          {/* Display the placeholder only if the Spline is not loaded or is out of view */}
          {isPlaceholderVisible && (
            <img
              src={theme === 'dark' ? particlesPlaceholderDark : particlesPlaceholderLight}
              alt="Loading placeholder"
              className="spline-placeholder"
            />
          )}
          {/* Spline iframe: controlled by visibility state */}
          <ErrorBoundary>
            <iframe
              ref={splineRef}
              src={splineUrl}
              onLoad={handleSplineLoad}
              onError={() => {
                console.error('Spline failed to load. Reloading scene.');
                // Force a URL update to reload the Spline scene
                setSplineUrl(getSplineSceneUrl(theme, window.innerWidth));
              }}
              title="Spline Scene"
              frameBorder="0"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          </ErrorBoundary>
        </div>
        <h2 className="hero-tagline" dangerouslySetInnerHTML={{ __html: t('hero.tagline') }}></h2>
      </div>
      <div className="hero-contact">
        <a href="https://ig.me/m/amdigitalstudio_" aria-label="Instagram" className="contact-icon">
          <img src={instagramIcon} alt="Instagram" className="instagram" />
        </a>
        <a href="https://twitter.com" aria-label="Twitter" className="contact-icon">
          <img src={xIcon} alt="X ex-Twitter" className="twitter" />
        </a>
        <a href="mailto:andreymanuilovweb@gmail.com" aria-label="Email" className="contact-icon">
          <img src={emailIcon} alt="Email" className="email" />
        </a>
        <a href="https://wa.me/37123204492" aria-label="WhatsApp" className="contact-icon">
          <img src={whatsappIcon} alt="WhatsApp" className="whatsapp" />
        </a>
        <a href="https://telegram.me/@am_digital_studio" aria-label="Telegram" className="contact-icon">
          <img src={telegramIcon} alt="Telegram" className="telegram" />
        </a>
      </div>
    </section>
  );
};

export default Hero;